import React from "react";
import { Link } from "gatsby";
import Categories from "../components/Categories";
import Layout from "../components/Layout";

export const CategoriesPageTemplate = () => (
  <div>
    <div className="container py-5">
      <nav aria-label="Page breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/categories">Categories</Link>
          </li>
        </ol>
      </nav>

      <div className="mb-5">
        <Categories />
      </div>
    </div>
  </div>
);

CategoriesPageTemplate.propTypes = {};

const CategoriesPage = () => {
  return (
    <Layout>
      <CategoriesPageTemplate />
    </Layout>
  );
};

export default CategoriesPage;
